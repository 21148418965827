import React from 'react'
import styles from './styles.module.scss'

const SkeletonTags = () => {
  return (
    <div className={styles.tagWrap}>
       <div className={styles.tags}></div>
       <div className={styles.tags}></div>
    </div>
   
  )
}

export default SkeletonTags;
