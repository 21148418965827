import { fetchApi } from '@/utils/apiCall'

export async function getSavedAuctionsProperties(lang, types = []) {
  let requestBody = { allUserActions: true }
    const response = await fetchApi({
      lang,
      baseUrl: 'NEXT_PUBLIC_AUCTION_API_URL_V2',
      subUrl: `getAllSavedAuctionsProperties`,
      method: 'POST',
      reqBody: requestBody,
    })

    const savedAuctionsProperties = types?.map((type) => {
       return(
        {type: type, ids: response?.data?.data?.[type]?.map(({entity_id}) => entity_id) || []}
       )
    })

    let savedAuctionsPropertiesObj = savedAuctionsProperties?.reduce((acc, cur) => {
      acc[cur.type] = cur?.ids || [];
      return acc;
    }, {});
    return savedAuctionsPropertiesObj || {}
  }
  export const getSuccessPageUrl = (requirementId, source, lang) => {
    const baseURL = `${lang == 'en' ? '/en' : ''}/mmsuccesspage`;
    const queryParams = {
      requirementId,
      source,
    };
  
    const successPageUrl = new URL(`${baseURL}`, `${process.env.NEXT_PUBLIC_WASALTBASE_URL}`);
  
    Object.keys(queryParams).forEach((key) => {
      if (queryParams[key] !== undefined && queryParams[key] !== null) {
        successPageUrl.searchParams.append(key, queryParams[key]);
      }
    })
    return successPageUrl
  }

  export const getRequirements = async (lang, setShowLimitingUser, handleSubmit) => {
    try{
    const response = await fetch(`${process.env.NEXT_PUBLIC_GATEWAY_URL_WASALT}requirement-manager/get-requirements`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        locale: lang,
        Authorization: localStorage.getItem('authToken'),
      },
    })
    if(!response.ok){
      throw new Error(`Failed to fetch get-requirements, HTTP error Status:${res.status}`)
    }
    const res = await response.json()
    if (res?.status) {
      if(res?.data?.nonPremium > 0){
        setShowLimitingUser()
      } else {
        handleSubmit()
      }
    } 
    }
    catch(error){
      throw new Error(`Error occurred in getRequirementsCount : ${error}`)
    }
  }