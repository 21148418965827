import React, { forwardRef } from 'react'
import styles from './style.module.scss'

const Button = (props, ref) => {
  const {
    id,
    classes,
    general,
    text,
    hide,
    onClick,
    label,
    style,
    right,
    iconOnly,
    iconName,
    withIcon,
    reverse,
    textClass,
    onMouseOver,
    onMouseOut,
    children,
    active,
    selected
  } = props

  const btnClasses = `${styles.btn} ${active ? styles.active : ''} ${selected? styles.selected : ''} ${styles[classes]} ${styles[general]} 
  ${right ? styles.right : ''} ${withIcon ? styles.withIcon : ''}
   ${iconOnly ? styles.iconOnly : ''} ${reverse ? styles.reverse : ''}
   ${hide ? styles.hide : ''}
   `

  return (
    <button
      ref={ref}
      id={id}
      type='button'
      title={label || text}
      className={btnClasses}
      onClick={onClick}
      style={style}
      onMouseOver={onMouseOver}
      onMouseOut={onMouseOut}
    >
      <span className={`${styles.icon}  ${styles[iconName]}`}></span>
      <span className={`${styles.text} ${styles[textClass]}`} dangerouslySetInnerHTML={{ __html: text }}></span>
      {children}
    </button>
  )
}

export default forwardRef(Button)
