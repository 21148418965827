import React, { useState } from 'react';
import IconoGraphy from '@/components/Common/IconoGraphy';
import styles from './FaqAccordion.module.scss'

const FaqAccordion = ({ title, answer, points }) => {

    const [expand, setExpand] = useState(false);

    return (
        <div className={styles.eWalletFaq}>
            <div className={styles.faqTitle}>
                <h3>{title}</h3>
                <div className={styles.arrow} onClick={() => setExpand(!expand)}>
                    <IconoGraphy iconClass={expand ? 'icon-chevron-up' : 'icon-chevron-down'} fontSize={'f_13'} />
                </div>
            </div>
            <p className={`${styles.answer} ${expand && styles.active}`}>
                {answer !== '' ? answer : null}
            </p>
            {points?.length > 0 ? 
                <ol className={`${styles.answer} ${styles.cuatomOl} ${expand && styles.active}`}>
                    {points?.map((point, index) =>  <li key={index}>{point}</li>)}
                </ol> : null
            }
        </div>
    )
};

export default FaqAccordion;