import IconoGraphy from '@/components/Common/IconoGraphy'
import Popup from '@/hoc/components/Popup'
import { pushDataLayerEventHandler, redirectOnWhatsapp } from '@/utils/utils'
import useTranslation from 'next-translate/useTranslation'
import styles from './styles.module.scss'
import { isDesktop } from 'react-device-detect'
import { getContactType, numberCall } from '@/utils/constant'
import { useEffect, useState } from 'react'

const ContactUs = ({ onClose }) => {
  const { t, lang } = useTranslation('translations-auction')
  const [contactDetails, setContactDetails] = useState({callNumber: '' , whatsapp: ''})

  useEffect(() => {
      fetchInfo()
  }, [])

  const fetchInfo = async() => {
    const reqHeaders = {
      'Content-Type': 'application/json',
      locale: lang,
    }
    const response = await fetch(`${process.env.NEXT_PUBLIC_API_URL_V1}getFeatureEntity?entityGroup=support_enquiries_auction_infath`, {
      headers: reqHeaders,
    })
    const details = await response.json()
    const callUs = getContactNumber(getContactType(0), details?.data) // to get number without country code
    const whatsapp = getContactNumber(getContactType(1), details?.data)?.split('+')?.[1] // to get number with country code but without + 
    setContactDetails({callNumber: callUs, whatsapp: whatsapp})
  }

  const handleWhatsappClick = (number) =>{
    let url = redirectOnWhatsapp(number,null,'','');
    window.open(url, isDesktop ? '_blank' : '_top')
    pushDataLayerEventHandler(window, lang, 'chat_now_clicked', 'Auction Home', { page_type:'Auction_SRP'})
  }

  //This function is used to filter contact number from backend response based on entity type 
  const getContactNumber = (entity, details) =>{
    return details?.filter(val => val.entity_name === entity)?.[0]?.entity_exfield1
  }

  const formatNumber = (number) => {
    if (number == '') {
      number = numberCall
    }
    const numberStr = number.toString();
    const formattedNumber = numberStr.replace(/(\d{3})(\d{3})(\d{4})/, '$1 $2 $3');
    return lang === 'ar' ? formattedNumber.split(' ').reverse().join(' ') : formattedNumber;
  }

  return (
    <Popup
      title={t('common.CONTACTUS')}
      onClose={onClose}
      isFullPopup
      customClass='stkyIn'
      customTitleClass='ContactHeader'
      showBack={true}
    >
      <div className={styles.cBoxDetails}>
        <div className={styles.imgBoxC}>
        <img src={`${process.env.NEXT_PUBLIC_CDN_BASE_URL}/images/contactUsImg.webp`} alt={t('auctionMenu.CONTACT_US')}/>
        </div>
        <div className={styles.contactBoxT}>
          <div className={styles.cTextPera}>{t('auctionCommon.REACH_US')}</div>
          <div className={styles.cNumberWrapper}>
            <div className={styles.cNumber}>{formatNumber(contactDetails?.callNumber.replace("+966", ""))}</div> 
            <div className={styles.cNumberText}>{`(${t('common.AM')} - ${t('common.PM')})`}</div>
          </div>

          <div className={styles.mainContainer}>
                <div className={`${styles.whatappCta} ${styles.ctaWrap}`}>
                    <button className={styles.whatAppButton} onClick={()=>handleWhatsappClick(contactDetails?.whatsapp)}>
                      <IconoGraphy iconClass={'icon-whatsapp'} iconColor={'color_green'} fontSize={'f_16'}></IconoGraphy>
                      {t('communicationChannels.ContactWhatsapp')}
                    </button>
                </div>
                <div className={`${styles.callCta} ${styles.ctaWrap}`}>
                  <a
                    href={`tel: ${contactDetails?.callNumber}`}
                    onClick={() =>
                      pushDataLayerEventHandler(window, lang, 'call_contactus_clicked', 'Auction_SRP', { source: 'Profile' })
                    }
                  >
                    <div className={styles.callButton}>
                      <IconoGraphy iconClass={'icon-call'} iconColor={'color_purple'} fontSize={'f_16'}></IconoGraphy>
                      {t('communicationChannels.call')}
                    </div>
                  </a>
                </div>
          </div>
        </div>
      </div>
    </Popup>
  )
}

export default ContactUs