import React from 'react'
import IconoGraphy from '../IconoGraphy'
import styles from './styles.module.scss'
import Button from '@/hoc/components/Button'
import useTranslation from 'next-translate/useTranslation'
import Options from './options'
import {pushDataLayerEventHandler} from '@/utils/utils'
const DropDown = ({
  dropdownProps,
  handleDropdownClick,
  handleSelectedValue,
  handleApply,
  handleClear,
  dataTestId
}) => {
  const { lang } = useTranslation('translations-auction')

  const handleButtonClick = (event) => {
    event.stopPropagation()
    handleDropdownClick(!dropdownProps.isOpen)
    if(dropdownProps.defaultTitle === 'Seller Type'){
      pushDataLayerEventHandler(window, lang, 'Seller_type_clicked','Auction Home', { page_type:'Auction_SRP'} )
    }else if(dropdownProps.defaultTitle === 'Auction Status'){
      pushDataLayerEventHandler(window, lang, 'Auction_status_clicked','Auction Home', { page_type:'Auction_SRP'} )
    }
  }
  return (
    <div data-testid={dataTestId} key={dropdownProps.filter_key} className={styles.dropdown}>
      <Button onClick={handleButtonClick} text={dropdownProps.title} classes='dropdownToggle' active={dropdownProps.isOpen} selected={dropdownProps.isActive}>
        <IconoGraphy iconClass={'icon-arrow-down'} iconColor={'color_gray'}></IconoGraphy>
      </Button>
      {dropdownProps.isOpen && (
        <Options
          className={dropdownProps.className}
          title={dropdownProps.title}
          defaultTitle={dropdownProps.defaultTitle}
          showClear={dropdownProps.showClear}
          dropdownKey={dropdownProps.filter_key}
          values={dropdownProps.values}
          handleDropdownClick={handleDropdownClick}
          handleSelectedValue={handleSelectedValue}
          handleApply={handleApply}
          handleClear={handleClear}
        />
      )}
    </div>
  )
}

export default DropDown
