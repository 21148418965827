import useTranslation from 'next-translate/useTranslation'
import Styles from './styles.module.scss'

const CitySearch = (props) => {
  const { t } = useTranslation('translations-auction')

  const renderList = (heading, values) => {
    return (
      <>
        <li className={Styles.cityHeading}>{heading}</li>
        {values?.map((city) => (
          <li
            className={`${Styles.cityName} ${city.title == props.cityProp ? Styles.selected : ''}`}
            key={city.cityId}
            onClick={() => props.onCitySelected(city)}
          >
            {city.title}
          </li>
        ))}
      </>
    )
  }

  return (
    <>
      <li
        className={`${Styles.cityName} ${Styles.allCities} ${!props.cityProp ? Styles.selected : ''}`}
        onClick={() => props.onCitySelected({ cityId: 'ALL' })}
      >
        {t('auctionCities.ALL')}
      </li>
      {props.searchCities &&
        props.searchCities?.popularCities?.length == 0 &&
        props.searchCities?.allCities?.length == 0 && (
          <li
            className={`${Styles.cityName} ${Styles.mt_18}`}
            onClick={() => props.noDataHandleClick && props.noDataHandleClick(props.inputValue, 'location')}
          >
            {t('auctionHome.NO_MATCH_FOUND')}
          </li>
        )}
      {props.searchCities && props.searchCities?.popularCities?.length > 0 && renderList(t('auctionHome.POPULAR_CITIES'), props?.searchCities?.popularCities)}
      {props.searchCities && props.searchCities?.allCities?.length > 0 && renderList(t('auctionHome.ALL_CITIES'), props?.searchCities?.allCities)}
    </>
  )
}

export default CitySearch
