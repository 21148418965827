import React from 'react'
import styles from './styles.module.scss'

const TermNCondMain = (props) => {
  const { termData , cssContent } = props
  return (
    <>
      {termData ? (
        <div>
           <style>{cssContent}</style>
          <div className={styles.legal_stuff}>
          </div>
          <div className={styles.term_box}>       
            <div>
            <div className={styles.termSection} dangerouslySetInnerHTML={{ __html: termData }} />
            </div>
          </div>
        </div>
      ) : (
        ''
      )}
    </>
  )
}

export default TermNCondMain
