import React from 'react'
import styles from './styles.module.scss'
import { usePagination, DOTS } from './usePagination'
import useTranslation from 'next-translate/useTranslation'
import IconoGraphy from '../IconoGraphy'

const Pagination = (props) => {
  const { data, onPageChange, totalCount, siblingCount, currentPage, pageSize, routerPage, isOpenAppHeader } = props
  const { t } = useTranslation('translations-auction')
  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  })

  if (isOpenAppHeader == null) {
    return null
  }

  function calcCurrentHits(page, hitsData, hitsCount) {
    if (hitsData?.length === 0) {
      return `0 - 0`
    } else if (page === 0 && hitsData?.length > 0) {
      return `1 - ${hitsData?.length}`
    } else if (page > 0 && hitsData?.length >= 15) {
      return `${15 * page + 1} - ${15 * (page + 1)}`
    } else {
      return `${15 * page + 1} - ${hitsCount}`
    }
  }

  if (currentPage < 0 || paginationRange < 2) {
    return null
  }

  const onNext = () => {
    if (lastPage != routerPage) {
      onPageChange(currentPage + 1)
    }
  }

  const onPrevious = () => {
    if (pageNo > 0) {
      onPageChange(currentPage - 1)
    }
  }

  let lastPage
  if (paginationRange) {
    lastPage = paginationRange[paginationRange.length - 1]
  }
  let totalHits = totalCount
  let pageNo = currentPage - 1
  const currentHits = calcCurrentHits(pageNo, data, totalHits)
  return (
    <div className={styles.center}>
      <ul>
        <div className={pageNo > 0 ? styles.prev : styles.disable} onClick={onPrevious}>
          <li>
            <IconoGraphy
              iconClass={'icon-chevron-left'}
              iconColor={pageNo > 0 ? 'color_white' : 'color_arrow'}
              fontSize={'f_13'}
              customClass={'boldness'}
            ></IconoGraphy>
          </li>
        </div>
        {paginationRange.map((pageNumber, index) => {
          if (pageNumber === DOTS) {
            return (
              <li className={styles.dot} key={index}>
                <IconoGraphy
                  iconClass={'icon-three-dots'}
                  iconColor={'color_gray_darker'}
                  fontSize={'f_16'}
                  customClass={'boldness'}
                ></IconoGraphy>
              </li>
            )
          }

          return (
            <li
              className={currentPage == pageNumber ? styles.selected : styles.nonSelected}
              onClick={() => onPageChange(pageNumber)}
              key={index}
            >
              {pageNumber}
            </li>
          )
        })}
        <div className={lastPage != routerPage ? styles.prev : styles.disable} onClick={onNext}>
          <li>
            <IconoGraphy
              iconClass={'icon-chevron-right'}
              iconColor={lastPage != routerPage ? '#fff' : 'color_arrow'}
              fontSize={'f_13'}
              customClass={'boldness'}
            ></IconoGraphy>
          </li>
        </div>
      </ul>
      <div className={`${styles.auctionCount} ${isOpenAppHeader ? styles.pd_90 : styles.pd_150}`}>
        {currentHits} {t('Search.OF')} {totalCount} {t('Search.PROPERTIES')}
      </div>
    </div>
  )
}

export default Pagination
