import IconoGraphy from '@/components/Common/IconoGraphy'
import { useClickOutsideListenerRef } from '@/utils/useClickOutsideListener'
import 'react-tippy/dist/tippy.css'
import TermNCondMain from '../Auction/TermNCondMain/index'
import styles from './styles.module.scss'

const TermsNConditions = (props) => {
  const handlePopupClose = () => {
    onClose()
  }
  const refPopup = useClickOutsideListenerRef(handlePopupClose)
  const { onClose, termData , cssContent } = props

  return (
    <div className={`${styles.modelOverlay} ${styles.termBackT}` }>
      <div className={`${styles.model} ${styles.termCdnModal}` } ref={refPopup}>
        <span className={styles.closeBtn} onClick={handlePopupClose}>
          <IconoGraphy iconClass={'icon-close'} iconColor={'color_revamp_close_icon'} fontSize={'f_8'}></IconoGraphy>
        </span>
        <TermNCondMain termData={termData} cssContent = {cssContent}/>
      </div>
    </div>
  )
}

export default TermsNConditions

const getIconPath = (icon, baseUrl) => {
  if (!icon) return null
  if (baseUrl) return `${baseUrl}/others/icons/${icon}`
  return icon
}
