import Popup from '@/hoc/components/Popup'
import useTranslation from 'next-translate/useTranslation'
import FaqAccordion from './FaqAccordion'
import styles from './FaqAccordion.module.scss'

const EWalletFAQ = ({ faqs, onClose }) => {
  const { t } = useTranslation('translations-auction')
  return (
    <Popup title={t('eWallet.FAQ_FULL_FORM')} onClose={onClose} isFullPopup showBack={true}>
      <div className={styles.FaqAccBox}>
        {faqs?.map(({ title, answer, points }) => (
          <FaqAccordion title={title} answer={answer} points={points} />
        ))}
      </div>
    </Popup>
  )
}

export default EWalletFAQ
