import React from 'react'
import styles from './styles.module.scss'
import Button from '@/hoc/components/Button'
import useTranslation from 'next-translate/useTranslation'
import { useClickOutsideListenerRef } from '@/utils/useClickOutsideListener'

const Options = ({
  showClear,
  className,
  defaultTitle,
  values,
  handleDropdownClick,
  handleSelectedValue,
  dropdownKey,
  handleApply,
  handleClear,
}) => {
  const { t } = useTranslation('translations-auction')

  const handleClickOutside = () => {
    handleDropdownClick(false, true)
  }
  const dropdownRef = useClickOutsideListenerRef(handleClickOutside)
  return (
    <>
      <div className={`${styles.dropdownMenu} ${className?styles[className]:""}`} ref={dropdownRef}>
        <div className={styles.menuList}>
          <span>{defaultTitle}</span>
          <ul>
            {values.map((value) => (
              <li
                className={value.isActive ? styles.active : ''}
                key={value.name}
                onClick={() => handleSelectedValue(value, dropdownKey)}
              >
                <div>{value.name}</div>
              </li>
            ))}
          </ul>
        </div>
        <div className={showClear ?  styles.menuFilter : styles.menuFilterClear}>
          {showClear && <span onClick={() => handleClear(dropdownKey)}>{t('auctionCommon.CLEAR')} </span>}
          <Button classes='applyFilter' onClick={() => handleApply()}>
            {t('auctionCommon.APPLY')}
          </Button>
        </div>
      </div>
    </>
  )
}

export default Options
