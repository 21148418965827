import React from 'react'

const IconContact = ({ width, height, iconTitle }) => {
  return (
    <svg
      width={width || '1em'}
      height={height || '1em'}
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      aria-labelledby='title'
    >
      <title>{iconTitle || 'contact'}</title>
      <path d="m10.514 10.264-1.222 1.528a12.89 12.89 0 0 1-4.584-4.584l1.528-1.222a1.038 1.038 0 0 0 .3-1.232L5.143 1.617a1.038 1.038 0 0 0-1.21-.583l-2.652.687A1.046 1.046 0 0 0 .51 2.875 15.407 15.407 0 0 0 13.625 15.99a1.044 1.044 0 0 0 1.153-.771l.688-2.651a1.038 1.038 0 0 0-.583-1.21l-3.137-1.393a1.04 1.04 0 0 0-1.232.299v0Z" stroke="#96989C" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  )
}

export default IconContact