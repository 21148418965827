import { useClickOutsideListenerRef } from '@/utils/useClickOutsideListener'
import { pushDataLayerEventHandler } from '@/utils/utils'
import useTranslation from 'next-translate/useTranslation'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import { useMediaQuery } from 'react-responsive'
import IconoGraphy from '../IconoGraphy'
import CitySearch from './citySearch'
import Styles from './styles.module.scss'

export default function AutoSearchDropDownNew({
  cities,
  userSelectedCity,
  onCitySelected,
  getCityAPI,
  renderThreeDots,
  isSearchOptionOpen,
  isFilterDropdownOpen,
}) {
  const { t, lang } = useTranslation('translations-auction')
  const router = useRouter()
  const [searchText, setSearchText] = useState(router.query.cityId ? userSelectedCity.title : t('auctionCities.ALL'))
  const [isDropDownOpen, setIsDropDownOpen] = useState(false)
  const [refValue1, setRefValue1] = useState(false)
  const [refValue2, setRefValue2] = useState(false)
  const outsideClickRef = useClickOutsideListenerRef(() => {
    setRefValue1(true)
    setTimeout(() => setRefValue1(false), 100)
  })
  const outsideClickRef2 = useClickOutsideListenerRef(() => {
    setRefValue2(true)
    setTimeout(() => setRefValue2(false), 100)
  })
  const isMobileTablet = useMediaQuery({ maxWidth: 1199 })

  const searchEvents = (city) => {
    let data = {
      search_keyword: searchText,
      search_result_clicked: city?.title,
      search_result_displayed: city?.auctionCount,
    }
    pushDataLayerEventHandler(window, lang, 'auction_search_clicked', 'auction home', data)
  }

  const setIsSearchOptionOpen = (isOpen) => {
    setIsDropDownOpen(isOpen)
    isSearchOptionOpen(isOpen)
  }

  useEffect(() => {
    if (userSelectedCity) {
      setSearchText(userSelectedCity.title)
    }
  }, [userSelectedCity])

  useEffect(() => {
    if (isFilterDropdownOpen) {
      setIsDropDownOpen(false)
    }
  }, [isFilterDropdownOpen])

  useEffect(() => {
    if (refValue1 && refValue2) {
      setIsSearchOptionOpen(false)
    }
  }, [refValue1, refValue2])

  return (
    <div className={Styles.mSearchF}>
      <div className={`${Styles.searchTopDDContainer} ${isDropDownOpen ? Styles.openSearchDrop : ''}`}>
        <div
          className={isDropDownOpen ? `${Styles.searchPLP} ${Styles.searchPLPOpen}` : `${Styles.searchPLP} `}
          ref={outsideClickRef}
        >
          <IconoGraphy
            iconClass={isDropDownOpen && isMobileTablet ? 'icon-arrow-back' : 'icon-search-new'}
            iconColor={'color_search_icon'}
            fontSize={'f_20'}
            clickHandler={() => {
              if (isDropDownOpen && isMobileTablet) {
                setIsSearchOptionOpen(false)
              } else {
                setIsSearchOptionOpen(true)
              }
            }}
          ></IconoGraphy>

          <input
            type='text'
            name='searchBox'
            placeholder={t('auctionHome.SEARCH_CITY')}
            value={searchText}
            onChange={(e) => {
              getCityAPI(e.target.value.trim())
              setSearchText(e.target.value)
            }}
            onClick={() => setIsSearchOptionOpen(true)}
            autoComplete='off'
          />
        </div>
      </div>

      <div
        className={
          isDropDownOpen
            ? `${Styles.selectListWrapper} ${Styles.searchPLPOpen}`
            : `${Styles.selectListWrapper} ${Styles.searchPLPClose}`
        }
        ref={outsideClickRef2}
      >
        <ul className={`${Styles.selectListContainer}`}>
          <CitySearch
            inputValue={searchText}
            searchCities={cities}
            onCitySelected={(city) => {
              onCitySelected(city)
              setIsSearchOptionOpen(false)
              setSearchText(city.cityId == 'ALL' ? t('auctionCities.ALL') : city.title)
              getCityAPI(city.cityId == 'ALL' ? '' : city.title)
              searchEvents(city)
            }}
            cityProp={userSelectedCity && userSelectedCity.title}
          />
        </ul>
      </div>
      {renderThreeDots && !isDropDownOpen && <div>{renderThreeDots()}</div>}
    </div>
  )
}
