import { useEffect, useState } from 'react'
import styles from './styles.module.scss'
import useTranslation from 'next-translate/useTranslation'
import 'react-tippy/dist/tippy.css'
import Popup from '../Popup'
import { howAuctionWorksVidAndEWalletPDF, youtubeIdExtract } from '@/utils/utils'
import LoaderImage from '../../../assets/images/loader.gif'
import Image from 'next/image'

const HowAuctionsWork = (props) => {
  const { t, lang } = useTranslation('translations-auction')
  const { onClose } = props
  const handlePopupClose = () => {
    onClose()
  }

  const [videoId, setVideoId] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  /**
   * react hook
   */
  useEffect(() => {
    howAuctionsWorkVid()
  }, [])

  const howAuctionsWorkVid = async () => {
    setIsLoading(true)
    const apiData = await howAuctionWorksVidAndEWalletPDF(lang)
    if (apiData) {
      const youtubeId = youtubeIdExtract(apiData?.video) // youtube ID will be extracted from this
      setVideoId(youtubeId)
      setIsLoading(false)
    }
  }

  const steps = [
    {
      url: '/images/w1.svg',
      title: t('auctionHowAuctionWorks.REGISTER'),
    },
    {
      url: '/images/w2.svg',
      title: t('auctionHowAuctionWorks.CREATEWALLET'),
    },
    {
      url: '/images/w3.svg',
      title: t('auctionHowAuctionWorks.BID'),
    },
  ]

  return (
    <Popup
      title={t('auctionHowAuctionWorks.TITLE')}
      showBack={true}
      isFullHeight={true}
      onClose={handlePopupClose}
      customTitleClass='HowWorkHeaderTitle'
      customModalBodyClass={styles.HowAuctionWorkBody}
    >
      <div className={styles.Howwork}>
        <div className={styles.videoBox}>
          {!isLoading && (
            <>
              <iframe
                className={styles.videoContainer}
                id='iframe'
                src={`https://www.youtube-nocookie.com/embed/${videoId}?rel=0&enablejsapi=1`}
                frameborder='0'
                allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                allowfullscreen='true'
              />
            </>
          )}
        </div>
        {isLoading && (
          <div className={styles.centered_loader}>
            <Image width='45' height='45' src={LoaderImage} alt='loading ...' />
          </div>
        )}
        <ul>
          {steps?.map((step, index, { length }) => (
            <li key={index}>
              <div className={styles.iconContainer}>
                <span className={styles.iconBox}>
                  <img src={step.url} />
                </span>
                {length - 1 !== index && <hr className={styles.dashline} />}
              </div>
              <div className={styles.Title}>
                <div className={styles.TitleNumber}> {`${t('auctionHowAuctionWorks.STEP')} ${index + 1}`} </div>
                <div className={styles.TitleInfo}> {step.title} </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </Popup>
  )
}

export default HowAuctionsWork
