import IconoGraphy from '@/components/Common/IconoGraphy';
import React from 'react';
import styles from './mobile-back-header.module.scss'
import useTranslation from 'next-translate/useTranslation';

const MobileBackHeader = ({ title, goBack, showDeskTopBackWithText = false, isLoginSuccessScreen = false }) => {
	const {t} = useTranslation('translations-auction')
	return (
		<div className={`${showDeskTopBackWithText ? styles.desktopBackHeader : styles.backHeader} ${isLoginSuccessScreen? styles.closeBtnForSuccess:''}`}>
			<button className={styles.BackBtn} onClick={goBack}>
				<IconoGraphy iconClass={isLoginSuccessScreen?'icon-close':'icon-arrow-back'} fontSize={'f_8'} />
			</button>
			{showDeskTopBackWithText && <span className={styles.backText}>{t('eWallet.Back')}</span>}
			<h2 className={styles.title}>{title}</h2>
		</div>
	)
}

export default MobileBackHeader